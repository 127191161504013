import React from "react";

import Post from "@/templates/Post";

const GuideArticleCardPreview = (props) =>
  <Post
    {...props}
    type="post"
  />;

export default GuideArticleCardPreview;
