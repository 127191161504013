import React from 'react';

import Hero from './Hero';
import Content from './Content';
import Bottom from './Bottom';
import Related from 'components/post/Related';

const ArticleDefault = ({ post }) => {

  return (
    <div>
      <Hero post={post} />
      <Content post={post} />
      <Bottom post={post} />
      <Related relatedPosts={post.relatedPosts.nodes || []} />
    </div>
  );
};

export default ArticleDefault;
