import React from 'react';

import Link from 'components/Link';
import EmailIcon from 'assets/icons/email.inline.svg';
import FacebookIcon from 'assets/icons/facebook.inline.svg';
import TwitterIcon from 'assets/icons/twitter.inline.svg';

const Social = ({ email, social }) => {
  return (
    <ul className="flex space-x-3 pt-6 sm:pt-0">
      {social.facebook && (
        <li>
          <Link
            href={social.facebook}
            className="w-12 h-12 sm:w-10 sm:h-10 rounded-full border border-text flex flex-row items-center justify-center hover:bg-text hover:text-white"
          >
            <FacebookIcon />
          </Link>
        </li>
      )}
      {social.twitter && (
        <li>
          <Link
            href={social.twitter}
            className="w-12 h-12 sm:w-10 sm:h-10 rounded-full border border-text flex flex-row items-center justify-center hover:bg-text hover:text-white"
          >
            <TwitterIcon />
          </Link>
        </li>
      )}
      {email && (
        <li>
          <Link
            href={`mailto:${email}`}
            className="w-12 h-12 sm:w-10 sm:h-10 rounded-full border border-text flex flex-row items-center justify-center hover:bg-text hover:text-white"
          >
            <EmailIcon />
          </Link>
        </li>
      )}
    </ul>
  )
}

export default Social;