import React from 'react';

const Person = ({ data }) => {
  return (
    <div className="flex">
      <div className="w-10 h-10 rounded-full border border-grey-3 sm:border-white overflow-hidden mr-3">
        <img
          src={data.avatar.url}
          alt={`${data.name}`}
        />
      </div>
      <div>
        <p className="text-sm font-medium">{data.name}</p>
        <p className="text-xs pt-1">{data.description}</p>
      </div>
    </div>
  )
}

export default Person;