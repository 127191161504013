const {
  commonPageData
} = require('./commonData.js');

const Post = (WpTag = '') => `
  ${commonPageData(WpTag)}
  content
  date
  featuredImage {
    node {
      altText
      sourceUrl
      srcSet
      ${WpTag &&
        `localFile {
            childImageSharp {
              gatsbyImageData
            }
          }`
      }
    }
  }
  id
  databaseId
  slug
  tags {
    nodes {
      name
      slug
      uri
    }
  }
  title
  excerpt
  uri
  author {
    node {
      name
      email
      avatar {
        url
      }
      description
      seo {
        social {
          facebook
          twitter
        }
      }
    }
  }
  categories {
    nodes {
      name
      slug
      uri
    }
  }
  relatedPosts {
    relatedPosts {
      relatedPostItemElement {
        ... on ${WpTag}Post {
          id
          date
          title
          categories {
            nodes {
              name
              slug
              uri
            }
          }
          author {
            node {
              name
              email
              avatar {
                url
              }
              description
              seo {
                social {
                  facebook
                  twitter
                }
              }
            }
          }
          tags {
            nodes {
              name
              slug
              uri
            }
          }
          featuredImage {
            node {
              sourceUrl
              ${WpTag &&
                `localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }`
              }
            }
          }
        }
      }
    }
  }
`;

module.exports.post = Post;
