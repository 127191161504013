import React from 'react';

import PreviewImage from 'components/ui/PreviewImage';
import PreviewText from 'components/ui/PreviewText';
import Link from 'components/Link';
import DateCategory from 'components/post/DateCategory';

const Related = ({ relatedPosts }) => {
  if (relatedPosts.length === 0) return null;

  return (
    <div className="fluid-container mt-10 sm:mt-16 lg:mt-24">
      <h4 className="text-h4 font-medium">Related posts</h4>
      <ul className="mt-4 sm:mt-6 lg:mt-8 flex flex-col lg:flex-row lg:space-x-6">
        {relatedPosts.map(post => (
          <li
            key={post.slug}
            className="mt-8 first:mt-0 lg:mt-0 pt-8 first:pt-0 lg:pt-0 border-t border-grey-3 first:border-none lg: border-none"
          >
            <Link
              href={post.uri}
            >
              <PreviewImage
                img={post.featuredImage?.node}
                placeholder={"BLURRED"}
                className="lg:h-48 flex items-center overflow-hidden"
              />
              <DateCategory
                className="pt-6 lg:pt-4"
                date={post.date}
                categories={post.categories?.nodes || []}
              />

              <h4 className="text-h4 font-medium pt-4 lg:3 lg:text-h5">{post.title}</h4>
              {post.excerpt && (
                <PreviewText
                  text={post.excerpt}
                  className="pt-6 text-text-2 lg:hidden"
                />
              )}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default Related;