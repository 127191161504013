import {
  gql,
  ApolloClient,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import headerCredentials from '../helpers/headerCredentials';

const updateRating = (rating, id) => {
  const options = {
    mutation: gql`
      mutation UpdateRatingMutation($rating: String, $clientMutationId: String) {
        __typename
        ratingMutation(input: {rating: $rating, clientMutationId: $clientMutationId}) {
          clientMutationId
          exampleOutput
        }
      }
    `,
    variables: {
      rating,
      clientMutationId: id
    },
  };

  const link = createHttpLink({
    uri: `${process.env.GATSBY_WP_URL}/graphql`,
    headers: {
      ...headerCredentials(),
    },
    credentials: 'include',
  });

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  });

  return client.mutate(options);
};

export default updateRating;
