import React from 'react';

import PreviewImage from 'components/ui/PreviewImage';
import DateCategory from 'components/post/DateCategory';
import Author from 'components/post/Author';

const Hero = ({ post }) => {
  return (
    <div className="relative">
      <div className="absolute top-0 right-0 h-full w-7/12 bg-grey-2 hidden lg:block"></div>
      <div className="relative z-2 fluid-container flex flex-col lg:flex-row justify-between pt-7 sm:pt-12 lg:pt-20 pb-2 sm:pb-4 lg:pb-20 items-center">
        <div className="basis-6/12">
          <PreviewImage
            img={post.featuredImage?.node}
            placeholder={"BLURRED"}
          />
        </div>
        <div className="basis-5/12 flex flex-col justify-center pt-8 lg:pt-0">
          <DateCategory
            date={post.date}
            categories={post.categories?.nodes || []}
          />
          <h1 className="text-h3 text-black font-medium pt-4 sm:pt-6 lg:pt-8">{post.title}</h1>
          <Author
            className="pt-8 sm:pt-6 lg:pt-12"
            author={post.author?.node}
          />
        </div>
      </div>
    </div>
  )
}

export default Hero;