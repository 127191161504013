import React, { useState, useCallback } from 'react';
import classnames from 'classnames';

import updateRating from 'api/updateRating';

import CheckIcon from 'assets/icons/checkbox-check.inline.svg';
import CrossIcon from 'assets/icons/checkbox-cross.inline.svg';

const Helpful = ({ className, id }) => {
  const [isSelected, setIsSelected] = useState(false);
  const [isHelpful, setIsHelpful] = useState(true);

  const handleHalpful = useCallback((state) => {
    if (isSelected) return;

    setIsHelpful(state);
    setIsSelected(true);

    const result = state ? 1 : -1;

    updateRating(String(result), String(id));
  }, [id, isSelected]);

  return (
    <div className={classnames(
      'flex flex-col sm:justify-between sm:flex-row items-center bg-grey-2',
      className
    )}>
      <p className="text-xl font-medium">Was this article helpful?</p>
      <div className="flex mt-5 sm:mt-0">
        <div
          className={classnames(
            'w-28 sm:w-24 mx-2 bg-white h-12 flex items-center justify-center border border-white cursor-pointer transition hover:border-black/20',
            isSelected && 'pointer-events-none',
            (!isHelpful && isSelected) && 'border-black'
          )}
          onClick={() => handleHalpful(false)}
        >
          {(!isHelpful && isSelected) && (
            <CrossIcon className="mr-1.5" />
          )}
          <span className="pt-0.5">No</span>
        </div>
        <div
          className={classnames(
            'w-28 sm:w-24 mx-2 bg-white h-12 flex items-center justify-center border border-white cursor-pointer transition hover:border-black/20',
            isSelected && 'pointer-events-none',
            (isHelpful && isSelected) && 'border-red'
          )}
          onClick={() => handleHalpful(true)}
        >
          {(isHelpful && isSelected) && (
            <CheckIcon className="mr-1.5" />
          )}
          <span className={classnames(
            'pt-0.5',
            (isHelpful && isSelected) && 'text-red'
          )}>Yes</span>
        </div>
      </div>
    </div>
  )
}

export default Helpful;