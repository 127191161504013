import React from 'react';
import moment from 'moment';

import Author from 'components/post/Author';
import Helpful from './Helpful';
import Subscribe from 'components/common/Subscribe';

const Bottom = ({ post }) => {
  return (
    <div className="fluid-container flex flex-row justify-center">
      <div className="max-w-3xl w-full">
        <div className="border-t border-grey-3 mt-10 sm:mt-14 lg:mt-16">
          <Author
            className="pt-8 sm:pt-6 lg:pt-12"
            author={post.author?.node}
          />
        </div>
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between pt-12 sm:pt-8 lg:pt-12 text-helv">
          <p className="text-xs">Published on {moment(post.date).format('MMMM D, YYYY')}</p>
          <p className="text-xs text-text-2">© Oncology Compass</p>
        </div>
        <Helpful className="p-6 sm:p-12 mt-10" id={post.databaseId} />
        <Subscribe 
          className="mt-6 sm:mt-8" 
          red={true}
        />
      </div>
    </div>
  )
}

export default Bottom;