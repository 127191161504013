import React from 'react';

const Content = ({ post }) => {
  return (
    <div className="fluid-container flex flex-row justify-center pt-8 sm:pt-10 lg:pt-20">
      <div
        className="w-full sm:max-w-3xl text-format"
        dangerouslySetInnerHTML={{ __html: post.content }}
      ></div>
    </div>
  )
}

export default Content;