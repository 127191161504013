import React from 'react';
import classnames from 'classnames';

import Person from './Person';
import Social from './Social';

const Author = ({ className, author }) => {
  return (
    <div className={classnames(
      'flex flex-col sm:flex-row justify-between sm:items-center',
      className
    )}>
      <Person 
        data={author}
      />
      <Social
        email={author.email}
        social={author.seo?.social}
      />
    </div>
  )
}

export default Author;