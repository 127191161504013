import React from 'react';

import Loader from 'components/Loader';
import ArticleTemplate from 'templates/PostPage';

import withPreview from 'hoc/withPreview';

import { post } from '@/constans/graphql/post.js';

const Article = ({
  pageContext,
  loaded
}) => {
  if (!loaded) return <Loader />;

  return <ArticleTemplate post={pageContext.post} />;
};

const queryPreview = `
query Post($id: ID = "") {
  post(id: $id, idType: DATABASE_ID, asPreview: true) {
    ${post()}
  }
}
`;

export default withPreview(Article, {
  queryPreview
});
